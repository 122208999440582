<template>
  <div class="position-relative">
    <b-form-group :label="label" :label-for="id" :class="formGroupClass">
      <b-select
        :id="id"
        class="rounded-sm"
        :value-field="valueField"
        :text-field="textField"
        v-model="model"
        :options="options"
        :input="inputValue()"
        :state="state != undefined ? state : null"
        :disabled="disabled != undefined ? disabled : disable"
        :size="size"
      >
        <template #first>
          <b-form-select-option :value="''" :disabled="defaultLabelDisabled">{{
            defaultLabel
          }}</b-form-select-option>
        </template>
      </b-select>
      <i class="mt-n1 fa fa-sync loadingIcon" v-if="disable" />
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: new Date().getTime().toString(),
    },
    label: {
      type: String,
      default: '',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    textField: {
      type: String,
      default: 'text',
    },
    defaultValue: { type: String, default: '' },
    options: Array | Object,
    state: { type: Boolean, default: undefined },
    defaultLabel: {
      type: String,
      default: 'Seçiniz',
    },
    defaultLabelDisabled: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    formGroupClass: { type: String, default: '' },
    disabled: { type: Boolean, default: undefined },
  },
  name: 'Select',
  data() {
    return {
      model: '',
      disable: true,
    };
  },
  created() {
    this.setDisabledStatus();
    this.setDefaultValue();
  },
  methods: {
    setDefaultValue() {
      this.model = this.defaultValue != '' ? this.defaultValue : '';
    },
    inputValue() {
      this.$emit('input', this.model);
    },
    setDisabledStatus() {
      this.disable = this.options.length < 1;
    },
  },
  watch: {
    defaultValue() {
      this.setDefaultValue();
    },
    options() {
      this.setDisabledStatus();
    },
    value(newVal) {
      this.model = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.loadingIcon {
  position: absolute;
  font-size: 14px;
  top: 45px;
  right: 30px;
  animation: rotation 4s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
